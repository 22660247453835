import { toast } from 'react-toastify';
import { Axios } from '../../service/serviceBasic';
import * as Types from './types';
import { messageErrorDefault } from '../../helper/constants';
import { paymentStatusEnum } from '../../helper/purchaseOrderEnum';
import { setConclusionMessage } from '../conclusion/actions';

export const setPurchaseOrder = (payload) => ({
  type: Types.SET_PURCHASE_ORDER,
  payload
})
export const setLoading = (payload) => ({
  type: Types.SET_LOADING,
  payload
})
export const setBankSlipLoading = (payload) => ({
  type: Types.SET_BANKSLIP_LOADING,
  payload
})
export const setError = (payload) => ({
  type: Types.SET_ERROR,
  payload
})


export const getPurchaseOrder = (history) => (dispatch, getState) => {
  const { condominiumRegistrationReducer } = getState();
  dispatch(setLoading(true));
  const request = {
    condominiumId: condominiumRegistrationReducer.form.condominiumRegistration_condominiumId
  }

  Axios.post('v1/purchaseOrder/getPurchaseOrderSubscription', request)
    .then((response) => {
      const purchaseOrder = response.data;
      purchaseOrder.valueTotal = purchaseOrder.valueGross - (purchaseOrder.valueDiscount || 0);
      dispatch(setPurchaseOrder(purchaseOrder));

      if (response.data?.paymentStatusId == paymentStatusEnum.Pago) {
        dispatch(setConclusionMessage({
          icon: "fa-check-circle-o",
          title: "PAGAMENTO EFETUADO COM SUCESSO!",
          discription: "",
          link: "",
          urlRedirect: null,
          clearStorage: true
        }));
        history.push('/conclusion')
      }

    })
    .catch((error) => {
      console.log(error?.message);
      dispatch(setError(error?.message || ''));
      dispatch(setPurchaseOrder(null));
    }).finally(() => {
      dispatch(setLoading(false))
    });
}
export const downloadBankSlip = (callback) => (dispatch, getState) => {
  dispatch(setBankSlipLoading(true));
  const condominiumId = getState().condominiumRegistrationReducer.form.condominiumRegistration_condominiumId;
  const { purchaseOrder } = getState().paymentMethodsReducer;

  const request = {
    purchaseOrderId: purchaseOrder.id,
    condominiumId: condominiumId
  }

  Axios.post('v1/bankSlip/generateAndGetInPdf', request)
    .then((response) => {
      const linkSource = `data:application/pdf;base64,${response.data.base64}`
      downloadBankSlipBase64(linkSource);

      dispatch(setConclusionMessage({
        icon: "fa-check-circle-o",
        title: "BOLETO EMITIDO COM SUCESSO!",
        discription: "Parabéns! Seu cadastro foi concluído com sucesso. Efetue o pagamento do boleto para ter acesso total aos recursos exclusivos do seu plano!",
        link: "",
        urlRedirect: null,
        clearStorage: true
      }))

      if (callback)
        callback();
    }).catch(() => {
      toast.warn(messageErrorDefault, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "ui-alert-toast",
        bodyClassName: "ui-alert-toast-content",
      });
    }).finally(() => {
      dispatch(setBankSlipLoading(false));
    })
}

const downloadBankSlipBase64 = (base64, fileName) => {
  const downloadLink = document.createElement("a");

  downloadLink.href = base64;
  downloadLink.download = `${fileName || 'boleto'}.pdf`;
  downloadLink.click();
}

const mockPurchaseOrder = {
  data: {
    "id": "a523bf91-fb5f-42bf-a249-430e0de4b988",
    "renegotiationId": null,
    "orderNumber": "244234051599726",
    "dateCompetence": "2024-03-12T00:00:00",
    "serviceOrderTypeId": "8f98b963-dd3a-4aeb-830d-4724b41151eb",
    "paymentStatusId": "a66619b4-e217-4dcc-bf74-eda2de96b8db",
    "dateDue": "2024-03-12T00:00:00",
    "valueGross": 210.00,
    "valueDiscount": null,
    "description": "1º Parcela Assinatura Condomínio",
    "paidOptionTypeId": null,
    "numberInstallmentsPaid": null,
    "taxCreditCardPaid": null,
    "dateBillPaid": null,
    "valuePaid": null,
    "idUserTransaction": "417f4015-70d0-45d6-9f30-0f23262a7203"
  }
}

