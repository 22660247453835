import React, { Component } from 'react';
import Button from '../../components/button'
import './conclusion.scss';


class Conclusion extends Component {


  componentDidMount() {
    if (!this.props.conclusion || !this.props.conclusion.title) {
      this.props.history.push('/condominiumRegistration');
      return;
    }

    if (this.props.conclusion.clearStorage) {
      setTimeout(() => {
        window.localStorage.clear();
      }, 1000)
    }

    if (this.props.conclusion.urlRedirect !== '' && this.props.conclusion.urlRedirect !== null && this.props.conclusion.urlRedirect !== undefined) {
      setTimeout(() => {
        this.props.history.push(this.props.conclusion.urlRedirect);
      }, 5000)
    }
  }

  render() {

    return (
      <div className="conclusion">
        <div className="col">
          <i className={`icon fa ${this.props?.conclusion?.icon}`}></i>
        </div>
        <div className="container">
          <div className="col">
            <h1 className="title">{this.props?.conclusion?.title}</h1>
            <span className="message">
              {this.props?.conclusion?.discription || 'Parabéns! Seu cadastro foi concluído com sucesso. Agora você tem acesso total aos recursos exclusivos do seu plano!'}
            </span>
          </div>
          {/* {this.props.conclusion.link && ( */}
          <div className="col link">
            <Button
              onclick={() => window.open(process.env.REACT_APP_URLPORTAL, '_blank')}
              content={
                <div>
                  <a href={process.env.REACT_APP_URLPORTAL} target="_blank">Acessar Portal ZIP</a>
                </div>
              } />
          </div>
          {/* )} */}
        </div>
      </div>)
  }
}

export default Conclusion;
